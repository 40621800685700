/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {getAccountRole, permissions, Role, rules} from '../../../../../app/routing/Roles'
import {AbacProvider, AllowedTo} from 'react-abac'
import {getTopicList} from '../../../../../app/modules/topic/core/_requests'
import {TopicQueryRequestProvider} from '../../../../../app/modules/topic/core/TopicQueryRequestProvider'
import {TopicQueryResponseProvider} from '../../../../../app/modules/topic/core/TopicQueryResponseProvider'


// const topicList = async() => {
//   return await getTopicList("items_per_page=100&page=1")
// }
//
// console.log(topicList())

const SidebarMenuMain = () => {
  const intl = useIntl()

  const topicList = async () => {
    const result1 = await getTopicList('items_per_page=100&page=1')
    const result2 = await getTopicList('items_per_page=100&page=1&status=Active,Inactive')
    console.log(result1.data)
    return result1?.data?.length !== 0 || result2?.data?.length !== 0
  }
  const [topicLists, setTopicLists] = useState<any>(false)

  const temp = setInterval(() => {

    if (!topicLists) {
      topicList().then((res) => {
        setTopicLists(res)
        console.log(topicLists)
        clearInterval(temp)
      })
    }
  }, 2000)


  const [accountRole, setAccountRole] = useState(Role.USER)
  getAccountRole().then((value) => setAccountRole(value))

  const user = {
    id: 1,
    role: [accountRole],
  }

  // console.log(user.role)

  const post = {
    id: 1,
    topicId: 1,
  }


  return (
    <>
      {/*<div style={{visibility: "hidden"}}>*/}
      <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/graphs/gra004.svg"
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon="bi-app-indicator"
      />
      {/*</div>*/}

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.TOPICS} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to="/topics"
            icon="/media/icons/duotune/general/gen005.svg"
            title="Topics"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.PHISHING_TOPICS} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to="/phishingtopics"
            icon="/media/icons/duotune/maps/map007.svg"
            title="Phishing Topics"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>


      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.VIDEO_TOPICS} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to="/videotopics"
            icon="/media/icons/duotune/general/gen025.svg"
            title="Video Topics"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.FACIAL_DETECTION} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to="/facedetection"
            icon="/media/icons/duotune/technology/teh002.svg"
            title="Face Detection"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.EMAIL_DIGEST} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to="/emaildigest"
            icon="/media/icons/duotune/maps/map010.svg"
            title="Email Digest"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>


      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.WIKI_DEFENDER} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to="/wikidefender"
            icon="/media/icons/duotune/maps/map005.svg"
            title="Wiki Defender"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>


      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.ALL_MESSAGES} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to="/content/feeds"
            title="Messages and Comments"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/general/gen055.svg"
          />
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.ALL_MESSAGES} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to='/content/all'
            title='All Messages'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen056.svg'
          />
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.ALL_VIDEOS} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to='/videocontent/all'
            title='All Videos'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen021.svg'
          />
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.REPORTS} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItemWithSub
            to="/reports"
            title="Report"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/files/fil003.svg"
          >
            <TopicQueryRequestProvider>
              <TopicQueryResponseProvider>
                <SidebarMenuItem to="/reports/report_pr" title="Phishing Report" hasBullet={true}/>
                {(topicLists) ?
                  <SidebarMenuItem to="/reports/report_sov_mda_integrated" title="Integrated Report"
                                   hasBullet={true}/> :
                  <div></div>}
              </TopicQueryResponseProvider>
            </TopicQueryRequestProvider>


          </SidebarMenuItemWithSub>
        </AllowedTo>
      </AbacProvider>


      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.ACCOUNTS} data={post} no={() =>
          <></>
        }>
          {/*<SidebarMenuItemWithSub*/}
          {/*  to='/crafted/accounts'*/}
          {/*  title='Accounts'*/}
          {/*  icon='/media/icons/duotune/communication/com006.svg'*/}
          {/*  fontIcon='bi-person'*/}
          {/*>*/}
          {/*  <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true}/>*/}
          <SidebarMenuItem to="/crafted/account/settings"
                           title="Profile"
                           icon="/media/icons/duotune/communication/com006.svg"
                           fontIcon="bi-layers"
          />
          {/*</SidebarMenuItemWithSub>*/}
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.USER_MANAGEMENT} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to='/apps/user-management/users'
            icon="/media/icons/duotune/general/gen051.svg"
            title="User Management"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.AGENT_MANAGEMENT} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to='/apps/agent-management/agents'
            icon="/media/icons/duotune/general/gen022.svg"
            title="Agent Management"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>

      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.CLIENT_MANAGEMENT} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to='/apps/client-management/clients'
            icon="/media/icons/duotune/communication/com014.svg"
            title="Client Management"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>
      <AbacProvider user={user} roles={user.role} rules={rules}>
        <AllowedTo perform={permissions.CROWS_TASK} data={post} no={() =>
          <></>
        }>
          <SidebarMenuItem
            to='/apps/CROWS-Task/CROWS-Task'
            icon="/media/icons/duotune/technology/teh003.svg"
            title="C.R.O.W.S. Neutralization System"
            fontIcon="bi-layers"
          />
        </AllowedTo>
      </AbacProvider>


      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
      {/*========================= Unused ============================*/}

    </>
  )
}

export {SidebarMenuMain}
