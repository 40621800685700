import {BoundingBox, Face, VideoKeywordQueryResponse, VideoTopic, VideoTopicQueryResponse} from './_models'
import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {CROWSTask} from '../../CROWSTask/core/_models'
import {Master, MasterQueryResponse} from '../../videocontent/components/masters-list/core/_models'

const API_URL = `/api/private/videotopic`
const API_URL2 = `/api/private/faceDetection`
const KEYWORD_API_URL = `/api/private/keyword`

const getTopicList = (query: string): Promise<VideoTopicQueryResponse> => {
  return axios
    .get(`${API_URL}/getVideoTopicList?${query}`)
    .then((d: AxiosResponse<VideoTopicQueryResponse>) => d.data)
}

const getKeywordList = (query: string): Promise<VideoKeywordQueryResponse> => {
  return axios
    .get(`${KEYWORD_API_URL}/getKeywordList?${query}`)
    .then((d: AxiosResponse<VideoKeywordQueryResponse>) => d.data)
}

const getTopicById = (id: ID): Promise<VideoTopic | undefined> => {
  return axios
    .get(`${API_URL}/getVideoTopicById/${id}`)
    // .then((response: AxiosResponse<Response<VideoTopic>>) => response.data)
    .then((response: Response<VideoTopic>) => response.data)
}
const getAllFaces = (): Promise< any| undefined> => {
  return axios
    .get(`${API_URL2}/getAllFaces`)
    // .then((response: AxiosResponse<Response<VideoTopic>>) => response.data)
    .then((response: any) => response)

}
const getFacesByTopicId = (id: ID): Promise< any| undefined> => {
  return axios
    .get(`${API_URL2}/getFacesByTopicId?vtopicId=${id}`)
    // .then((response: AxiosResponse<Response<VideoTopic>>) => response.data)
    .then((response: any) => response.data.data)

}





const updateFace = (formData:FormData): Promise<Face|undefined> => {


  return axios.put(`${API_URL2}/updateFace`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((d: Response<Face>) => d.data);






}
const createFace = (formData:FormData): Promise<Face|undefined> => {


  return axios.post(`${API_URL2}/createFace`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((d: Response<Face>) => d.data);






}
const getFaceById = (id:ID): Promise<Face|undefined>=>{
  return axios
    .get(`${API_URL2}/getFaceById/${id}`)
    // .then((response: AxiosResponse<Response<VideoTopic>>) => response.data)
    .then((response: any) => response.data)

}
const deleteFaceItem = (id:ID): Promise<any>=>{
  return axios
    .delete(`${API_URL2}/deleteFaceItem/${id}`)
    // .then((response: AxiosResponse<Response<VideoTopic>>) => response.data)
    .then((response: any) => response.data)

}
const createTopic = (topic: VideoTopic): Promise<VideoTopic | undefined> => {
  if (topic.exclusionReturn != null) {
    topic.exclusion = JSON.stringify(topic.exclusionReturn, null, 0).toString()
  }
  if (topic.mediaReturn != null) {
    topic.media = JSON.stringify(topic.mediaReturn, null, 0).toString()
  }
  return axios
    .put(`${API_URL}/createVideoTopic`, topic)
    .then((response: AxiosResponse<Response<VideoTopic>>) => response.data)
    .then((response: Response<VideoTopic>) => response.data)
}

const updateTopic = (topic: VideoTopic): Promise<VideoTopic | undefined> => {
  if (topic.exclusionReturn != null) {
    topic.exclusion = JSON.stringify(topic.exclusionReturn, null, 0).toString()
  }
  if (topic.mediaReturn != null) {
    topic.media = JSON.stringify(topic.mediaReturn, null, 0).toString()
  }
  return axios
    .post(`${API_URL}/updateVideoTopic/${topic.id}`, topic)
    .then((response: AxiosResponse<Response<VideoTopic>>) => response.data)
    .then((response: Response<VideoTopic>) => response.data)
}

const deleteTopic = (topicId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/deleteVideoTopic/${topicId}`).then(() => {
  })
}

const deleteSelectedTopics = (topicIds: Array<ID>): Promise<void> => {
  const requests = topicIds.map((id) => axios.delete(`${API_URL}/deleteVideoTopic/${id}`))
  return axios.all(requests).then(() => {
  })
}

const getTopicForDashBoard = (query: string): Promise<VideoTopicQueryResponse> => {
  return axios
    .get(`${API_URL}/getVideoTopicForDashBoard?${query}`)
    .then((d: AxiosResponse<VideoTopicQueryResponse>) => d.data)
}

const getTopicForFilter = (query: string): Promise<VideoTopicQueryResponse> => {
  return axios
    .get(`${API_URL}/getVideoTopicForFilter?${query}`)
    .then((d: AxiosResponse<VideoTopicQueryResponse>) => d.data)
}

const getMasterByFaceId = (id:ID): Promise<Master[]> => {
  // console.log(query)

  return axios
    .get(`${API_URL2}/getMasterByFaceId/${id}`)
    .then((d: AxiosResponse<Master[]>) => d.data)

}

const getBoundingBoxByJobId= (id:string, faceId:number): Promise<BoundingBox[]> => {
  // console.log(query)

  return axios
    .get(`${API_URL2}/getBoundingBoxByJobId/${id}/${faceId}`)
    .then((d: AxiosResponse<BoundingBox[]>) => d.data)

}
  export {
    createFace,
    updateFace,
    getTopicList,
    getKeywordList,
    getTopicById,
    getFacesByTopicId,
    getFaceById,
    createTopic,
    updateTopic,
    deleteTopic,
    deleteSelectedTopics,
    getTopicForDashBoard,
    getTopicForFilter,
    deleteFaceItem,
    getMasterByFaceId,
    getAllFaces,
    getBoundingBoxByJobId,
  }
