import {Face} from './core/_models'
import React, {Dispatch, FC, SetStateAction, useState} from 'react'
import * as Yup from 'yup'
import {useQueryResponse} from './core/TopicQueryResponseProvider'
import {TopicsListLoading} from './components/loading/TopicsListLoading'
import {useNavigate} from 'react-router-dom'
import {FaceDetectionListItem} from './FaceDetectionListItem'

type Props = {
  isTopicLoading: boolean
  aFaces: Face[]
  setFaceDetectionEdit: Dispatch<SetStateAction<number|undefined|null>>

}


const editTopicSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),

  keywordMust: Yup.string()
    .min(1, 'It cannot be blanked!')
    .required('Keyword is required'),

  keywordAny: Yup.string()
    .min(1, 'It cannot be blanked!')
    .required('Keyword is required'),
})

const FaceDetectionListModal: FC<Props> = ({setFaceDetectionEdit, aFaces, isTopicLoading}) => {
  const [addNew, setAddNew] = useState(false)
  const [faces, setFaces] = useState(aFaces)
  const {refetch} = useQueryResponse()
  const navigate = useNavigate()
  return (

    <>
      <div className="card mb-5 mb-xl-10 h-auto " id="kt_profile_details_view">

        <div
          className="card-header border-0 cursor-pointer "
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title w-100 m-0 justify-content-between">
            <h3 className="fw-bolder ">Face Detection Reports</h3>
          </div>
        </div>
          {/* begin::Scroll */}


            {/*////////////////g///////////////////*/}
            {/*NAME*/}
            {/* begin::Input group */}
            {
          faces.map(face=>
            <FaceDetectionListItem setFaceList={setFaces} refetch={refetch} setFaceDetectionEdit={setFaceDetectionEdit} face={face}/>)}
          </div>

          {/*///////////////////////////////////*/}
        {(isTopicLoading) && <TopicsListLoading/>}
        <br/>
    </>
  )
}
export {FaceDetectionListModal}
