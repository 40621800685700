import {FC, lazy, Suspense, useState} from 'react'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import AgentPage from '../modules/apps/agent-management/AgentPage'
import {AbacProvider, AllowedTo} from 'react-abac'
import {getAccountRole, permissions, Role, rules} from './Roles'
import ClientPage from '../modules/apps/client-management/ClientPage'
import {FaceDetectionListModal} from '../modules/facedetection/FaceDetectionListModal'
import {FaceDetectionModalWrapper} from '../modules/facedetection/FaceDetectionModalWrapper'



const PrivateRoutes = () => {
  const ContentPage = lazy(() => import('../modules/content/ContentPage'))
  const ReportPage = lazy(() => import('../modules/reports/ReportsPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const TopicPage = lazy(() => import('../modules/topic/TopicMainPage'))
  const VideoPage = lazy(() => import('../modules/videotopic/VideoTopicMainPage'))
  const VideoContentPage = lazy(() => import('../modules/videocontent/VideoContentPage'))
  const PhishingPage = lazy(() => import('../modules/phishing/TopicMainPage'))
  const EmailDigestPage = lazy(() => import('../modules/emaildigest/EmailDigestMainPage'))
  const WikiDefenderPage = lazy(() => import('../modules/wikidefender/WikiDefenderMainPage'))
  const CROWSTaskPage = lazy(() => import('../modules/CROWSTask/CROWSTaskMain'))
  const CreditMainPage = lazy(() => import('../modules/credits/CreditMainPage'))
  // const [accountUser, setAccountUser] = useState({"userID": 0, "userRole": ""})

  const [accountRole, setAccountRole] = useState(Role.USER)
  getAccountRole().then((value) => setAccountRole(value))

  const user = {
    id: 1,
    role: [accountRole],
  }

  // console.log(user.role)

  const post = {
    id: 1,
    topicId: 1,
  }

  return (
    <Routes>
      <Route element={<MasterLayout/>}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to={localStorage.getItem('logUrl') || '/dashboard'}/>}/>
        {/* Pages */}
        <Route path="dashboard" element={

          <DashboardWrapper/>

        }/>

        <Route path="topics/*" element={

          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.PHISHING_TOPICS} data={post}>
              <SuspensedView>
                <TopicPage/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }/>
        <Route path="phishingtopics/*" element={

          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.PHISHING_TOPICS} data={post}>

              <SuspensedView>
                <PhishingPage/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }/>
        <Route path="content/*" element={
          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.ALL_MESSAGES} data={post}>

              <SuspensedView>
                <ContentPage/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }
        />

        <Route path="videotopics/*" element={

          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.VIDEO_TOPICS} data={post}>

              <SuspensedView>
                <VideoPage/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }
        />

        <Route path="facedetection/*" element={

          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.FACIAL_DETECTION} data={post}>

              <SuspensedView>
                <FaceDetectionModalWrapper/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }
        />
        <Route path="emaildigest/*" element={
          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.EMAIL_DIGEST} data={post}>

              <SuspensedView>
                <EmailDigestPage/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }/>

        <Route path="wikidefender/*" element={
          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.WIKI_DEFENDER} data={post}>

              <SuspensedView>
                <WikiDefenderPage/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }/>

        <Route path="videocontent/*" element={

          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.ALL_VIDEOS} data={post}>

              <SuspensedView>
                <VideoContentPage/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }
        />
        <Route path="credit/*" element={

          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.CREDIT} data={post}>

              <SuspensedView>
                <CreditMainPage/>
              </SuspensedView>

            </AllowedTo>
          </AbacProvider>
        }
        />
        <Route path="reports/*" element={
          <AbacProvider user={user} roles={user.role} rules={rules}>
            <AllowedTo perform={permissions.REPORTS} data={post}>
              <SuspensedView>
                <ReportPage/>
              </SuspensedView>
            </AllowedTo>
          </AbacProvider>
        }
       />
        {/*<Route path='builder' element={<BuilderPageWrapper />} />*/}
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <AbacProvider user={user} roles={user.role} rules={rules}>
              <AllowedTo perform={permissions.USER_MANAGEMENT} data={post}>
                <SuspensedView>
                  <UsersPage/>
                </SuspensedView>
              </AllowedTo>
            </AbacProvider>
          }
        />
        <Route
          path='apps/agent-management/*'
          element={
            <AbacProvider user={user} roles={user.role} rules={rules}>
              <AllowedTo perform={permissions.AGENT_MANAGEMENT} data={post}>
                <SuspensedView>
                  <AgentPage/>
                </SuspensedView>
              </AllowedTo>
            </AbacProvider>
          }
        />
        <Route
          path='apps/client-management/*'
          element={
            <AbacProvider user={user} roles={user.role} rules={rules}>
              <AllowedTo perform={permissions.CLIENT_MANAGEMENT} data={post}>
                <SuspensedView>
                  <ClientPage/>
                </SuspensedView>
              </AllowedTo>
            </AbacProvider>
          }
        />
        <Route
          path='apps/CROWS-Task/*'
          element={
            <AbacProvider user={user} roles={user.role} rules={rules}>
              <AllowedTo perform={permissions.CROWS_TASK} data={post}>
                <SuspensedView>
                  <CROWSTaskPage/>
                </SuspensedView>
              </AllowedTo>
            </AbacProvider>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
