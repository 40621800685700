import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Face} from './core/_models'
import Swal from 'sweetalert2'
import {deleteFaceItem, getFacesByTopicId} from './core/_requests'

type Props = {
  face: Face
  setFaceDetectionEdit: Dispatch<SetStateAction<number|undefined|null>>
  refetch:()=>void
  setFaceList:Dispatch<SetStateAction<Face[]>>
}
const base64ToBlob = (base64: string, type = 'image/png') => {
  const byteCharacters = atob(base64); // Decode Base64
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: type});
}
const FaceDetectionListItem: FC<Props> = ({setFaceList, refetch, face, setFaceDetectionEdit}) => {
  // const {faceDetectionUpdateId, setFaceDetectionUpdateId} = useListView()
  const [filePreview, setFilePreview] = useState<string|undefined>(undefined)
  const navigate = useNavigate()
  const [reportColour, setReportColour] = useState<boolean>()
  useEffect(()=>{
    if(face.status != "SUCCESS"&&face.status != "QUEUE"){
      setChangeDisabled(true)
    }else{
      setChangeDisabled(false)
    }
  },[face.status])
  useEffect(()=>{
    console.log(face.imagePreview?.[0])
    setFilePreview( face.imagePreview?.[0] ? URL.createObjectURL(base64ToBlob(face.imagePreview?.[0])) : '');
  },[face])

  const [hover, setHover] = useState(false)
  const [hoverDelete, setHoverDelete] = useState(false)
  const [changeDisabled, setChangeDisabled] = useState(false)
  const [expand, setExpand] = useState(false)

  let bgColour; // Background color
  let txColour; // Text color

  switch (face.status) {
    case "SUCCESS":
      bgColour = "#eefdf3";
      txColour = "#379154";
      //setChangeDisabled(true)
      break;
    case "FAILED":
      bgColour = "#fdf2f2";
      txColour = "#eb9696";
      //setChangeDisabled(false)
      break;
    case "QUEUE":
      bgColour = "#f1f4fd";
      txColour = "#5c7ee8";
      //setChangeDisabled(false)
      break;
    case "IN PROGRESS":
      bgColour = "#ebfdff";
      txColour = "#50d3e4";
      //setChangeDisabled(false)
      break;
    default:
      // Handle unexpected values if necessary
      bgColour = "#ffffff"; // Fallback background color
      txColour = "#000000"; // Fallback text color
      //setChangeDisabled(false)
      break;
  }

  return (

    <>
      {<div className="card  h-auto " id="kt_profile_details_view" onClick={()=>{setExpand(!expand)
        setHover(false)}}style={{marginLeft:"40px",marginRight:"40px", marginBottom:"40px", backgroundColor:hover?"#e9edf1":"", boxShadow:"4px black solid", height:"180px"}}onMouseEnter={()=>{setHover(true)}}onMouseLeave={()=>{setHover(false)}}>



          {}

          {<div className="d-flex flex-row justify-content-between">
            <div className="ps-4 pt-4 d-flex flex-row justify-content-between">
              <div style={{ width: '140px', height: '100px' , margin:"0", padding:"0", overflow:"hidden", borderRadius:"10%", justifyContent: "center", alignItems: "center", alignContent: "center", justifyItems: "center"}}>
              <img className="mx-0 my-0"src={filePreview} alt="Preview" style={{ width:"140px", margin:"0", padding:"0", borderRadius:"10%"}} />
              </div>
              <div className="d-flex flex-column ps-4 justify-content-between">
                <span style={{fontWeight:"bold", fontSize:"20px", alignItems:"between"}}>{face.target}</span>
                <div>
                <div style={{fontWeight:"bold",color:"gray"}}>
                  </div>
                <div style={{fontWeight:"bold",color:"gray"}}>
                  Images: {face.imageCount}
                </div>
                </div>

                </div>


            </div>
            <div className="d-flex flex-row">
            <div className="d-flex flex-column ps-4 pt-4  pe-4 justify-content-between">
              <span style={{fontWeight:"bold", fontSize:"16px", color:"gray"}}>Scan Status:<div style={{textAlign:"center", justifyContent:"center",alignItems:"center", alignContent:"center", fontSize:"14px",color:txColour, backgroundColor:bgColour, padding:"2px", borderRadius:"10%"}}>{face.status}</div></span>
              <button
                type='button'
                className='btn btn-outline-primary mx-2 my-4'
                style={{ border: "1px #009ef7 solid", color:reportColour?"#FFFFFF":"#009ef7"}}
                onClick={()=>{navigate(`../videotopics/faceReport/${face.id}`)}}
                onMouseEnter={()=>{setReportColour(true)}}
                onMouseLeave={()=>{setReportColour(false)}}
              >
                Report
              </button>
            </div>
              <div className={`d-flex flex-col align-items-center h-100 w-40px mx-6 }`}>
              <i className={`fa-solid fa-trash fs${changeDisabled?"disabled":""}`}
                 style={{fontSize:"20px", color:!changeDisabled&&hoverDelete?"red":""}}
                 onMouseEnter={()=>{setHoverDelete(true)}}
                 onMouseLeave={()=>{setHoverDelete(false)}}
                 onClick={async () => {
                   if(changeDisabled){return;}
                   Swal.fire({
                     icon: 'warning',
                     title: `<strong>Confirmation</strong>`,
                     html: `<i>Are you sure you want to delete this CROWS Task?</i>`,
                     heightAuto: false,
                     confirmButtonText: 'Confirm',
                     cancelButtonText: 'Cancel',
                     showCancelButton: true,

                   }).then((result) => {
                     if (result.isConfirmed) {
                       deleteFaceItem(face.id).then(()=>{
                         getFacesByTopicId(face.vtopicId)
                           .then((d)=>{
                             console.log("[+] LOG:",d,d.data,)
                             setFaceList(d)
                           })
                       })
                       // refetch()
                     }
                   })
                 }}></i>
</div>
              </div>
            </div>
          }
        <br/>
      </div>}
        {/*</div>*/}
    </>
  )
}
export {FaceDetectionListItem}
