import {useListView} from './core/ListViewProvider'
import {isNotEmpty, QUERIES} from './../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getAllFaces, getFaceById, getFacesByTopicId, getTopicById} from './core/_requests'
import {QueryRequestProvider} from './../apps/client-management/clients-list/core/QueryRequestProvider'
import {QueryResponseProvider} from './../apps/client-management/clients-list/core/QueryResponseProvider'
import {FaceDetectionListModal} from './FaceDetectionListModal'
import {useEffect, useState} from 'react'
import {Face} from './core/_models'

const FaceDetectionModalWrapper = () => {
  const {faceDetectionUpdateId, setFaceDetectionUpdateId} = useListView()
  const [faceDetectionEdit, setFaceDetectionEdit] = useState<number|undefined|null>(undefined)
  const enabledQuery: boolean = isNotEmpty(faceDetectionUpdateId)
  const [faceDetail, setFaceDetail] = useState<Face[]|undefined>(undefined)
  useEffect(()=>{
    getAllFaces().then(
      (d)=>{
        console.log("d",d)
        setFaceDetail(d.data)

      }

    )
  },[faceDetectionEdit])

  const {
    isLoading,
    data: face,
    error,
  } = useQuery(
    `${QUERIES.FACE_DETECTION_LIST}-faceDetection`,
    () => {
      return getAllFaces()
    },

    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        if(setFaceDetectionUpdateId!==undefined) {
          setFaceDetectionUpdateId(undefined)
        }
        console.error(err)
      },
    },
  )
  console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaa", faceDetail)

  if (!isLoading && !error && faceDetail && faceDetail.length>0) {
      return (

        <QueryRequestProvider>
          <QueryResponseProvider>
            <FaceDetectionListModal isTopicLoading={isLoading} aFaces={faceDetail}
                                    setFaceDetectionEdit={setFaceDetectionEdit}/>
          </QueryResponseProvider>
        </QueryRequestProvider>
      )


    }

    return null


}

export {FaceDetectionModalWrapper}
